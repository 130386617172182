// stores/isMobile.js
import {
	defineStore
} from 'pinia';

export const useIsMobileStore = defineStore('isMobile', {
	state: () => ({
		isMobile: false
	}),
	getters: {
		getState() {
			return this.isMobile;
		}
	},
	actions: {
		updateStore(val) {
			this.isMobile = val;
		}
	}
});
