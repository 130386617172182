<template>
	<div class="content">
		<v-footer class="footer">
			<div class="links">
				<span v-for="link in links" :key="link">{{ link }}</span>
			</div>

			<div class="icons pt-6">
				<v-btn class="icon" v-for="icon in icons" :key="icon" :icon="icon"></v-btn>
			</div>

			<div class="pt-6 rs">
				<span> Let's just pretend we just bought a lottery ticket!</span>

				©{{ new Date().getFullYear() }} HASH.FUN All rights reserved
			</div>
		</v-footer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				icons: [
					'mdi-facebook',
					'mdi-twitter',
					'mdi-linkedin',
					'mdi-instagram',
				],
				links: [
					'Home',
					'About Us',
					'Services',
					'Support',
					'Contact Us',
				],
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 8px 0 8px 0;

		@media (max-width:768px) {
			padding: 8px 0 70px 0;
		}
	}

	.footer {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: center;
		width: calc(100% - 32px);
		max-width: 1250px;
		background-color: transparent;
		color: #9c9cab;

		border-top: 1px solid #323746;

		padding: 15px 0px;

		.links {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 20px;

			span {
				color: var(--gray);
				transition: .3s ease-in-out;
				font-size: 15px;
				font-weight: 600;
				margin-top: 3px;
				cursor: pointer;
			}

			span:hover {
				color: #fff;
			}
		}

		.icons {
			.icon {
				margin-right: 20px;
				background-color: #9c9cab;
			}
		}
	}

	.rs {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 20px;
		font-weight: 500;
	}
</style>