<template>
	<div class="ranking">
		<div class="content">
			<div class="t">
				<div class="t-left">
					<div class="img">
						<v-img width="360" aspect-ratio="16/9" src="./img/ranking.png"></v-img>
					</div>
					<div class="token-ranking">
						<span class="title">24H Trading activity</span>
						<div class="tokens">
							<div class="item">
								<div class="item-title">
									<span class="number"></span>
									<div class="c1 col" :class="isMobile?'bl0':''">Name(Symbol)</div>
									<div class="c2 col">Market Cap</div>
									<div class="c3 col">Activity</div>
								</div>
								<div class="item-meta" v-for="i in 10" :key="i">
									<span class="number" :class="i==10?'bt0':''">#{{i}}</span>
									<div class="c1 col" :class="[isMobile?'bl0':'',i==10?'bt0':'']">
										<a-avatar :size="35"
											src="https://cdn.sunpump.meme/public/logo/DST_TJEcJe_STdFvjsncHL0.jpg"
											shape="square" />
										<span class="ml-2 name">Trony Vercetti ADS($ PROMOADS)</span>
									</div>
									<div class="c2 col" :class="i==10?'bt0':''">$70.1K</div>
									<div class="c3 col" :class="i==10?'bt0':''">1000 TX</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="t-right">
					<div class="token-ranking">
						<span class="title">24H Trading Volume</span>
						<div class="tokens">
							<div class="item">
								<div class="item-title">
									<span class="number"></span>
									<div class="c1 col" :class="isMobile?'bl0':''">
										Name(Symbol)
									</div>
									<div class="c2 col">Market Cap</div>
									<div class="c3 col">Volume</div>
								</div>
								<div class="item-meta" v-for="i in 10" :key="i">
									<span class="number" :class="i==10?'bt0':''">#{{i}}</span>
									<div class="c1 col" :class="[isMobile?'bl0':'',i==10?'bt0':'']">
										<a-avatar :size="35"
											src="https://cdn.sunpump.meme/public/logo/DST_TJEcJe_STdFvjsncHL0.jpg"
											shape="square" />
										<span class="ml-2">Trump ($TRUMP)</span>
									</div>
									<div class="c2 col" :class="i==10?'bt0':''">$70.1K</div>
									<div class="c3 col" :class="i==10?'bt0':''">$1000</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'v-ranking',
		data() {
			return {

			}
		},
		computed: {
			isMobile() {
				return this.$vuetify.display.mobile;
			}
		},
		watch: {},
		mounted() {},
		methods: {

		}
	}
</script>

<style lang="scss">
	.ranking {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.content {
			width: calc(100% - 32px);
			max-width: 1240px;
			will-change: transform;
			margin: 35px 0;

			@media (max-width:768px) {
				margin: 25px 0;
			}

			.t {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				justify-content: space-between;
				flex-wrap: wrap;
				gap: 30px;
				aspect-ratio: 3.659;

				.t-left {
					width: 100%;
					max-width: 550px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;
					gap: 30px;

					.img {
						width: 100%;
						max-width: 360px;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;
					}
				}

				.t-right {
					width: 100%;
					max-width: 550px;
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					justify-content: center;
					gap: 30px;

					.img {
						width: 100%;
						max-width: 360px;
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						justify-content: center;
					}
				}
			}
		}
	}

	.token-ranking {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;

		.title {
			font-size: 25px;
			font-weight: bold;
			color: #F5F5F5;
		}

		.tokens {
			flex: 1;
			border-radius: 10px;
			overflow: hidden;
			background-image: url(../../public/img/bg.svg);
			background-position: top right -200%;
			background-size: 500px;
			background-repeat: no-repeat;

			box-shadow: 1px 0px 1px 2px rgba(32, 40, 56, 0.8);

			.item {
				width: 100%;
				display: flex;
				flex-direction: column;


				.item-title,
				.item-meta {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					height: 60px;
					cursor: pointer;
					color: #F5F5F5;

					.col {
						height: 100%;
						display: flex;
						align-items: center;
						text-align: center;
						border-bottom: 1px solid #20283850;
						font-size: 15px;

						@media (max-width:768px) {
							font-size: 14px;
						}

					}

					.c1 {
						flex: 3;
						display: flex;
						flex-direction: row;
						justify-content: start;

						.name {
							width: 200px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						@media (max-width:768px) {
							padding-left: 15px;

							.name {
								width: 100px;
							}
						}

					}

					.c2 {
						flex: 1.5;
						line-height: 20px;
						justify-content: center;

						@media (max-width:768px) {
							justify-content: center;
						}
					}

					.c3 {
						width: 80px;
						justify-content: center;
					}

					.number {
						width: 60px;
						height: 100%;

						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						border-bottom: 1px solid #20283850;

						@media (max-width:768px) {
							display: none;
						}
					}

				}

				.item-title {
					.col {
						font-weight: 600;
					}
				}

				.item-meta:hover {
					background-color: #33333330;
				}
			}
		}
	}

	.bl0 {
		border-left: none !important;
	}

	.bt0 {
		border-bottom: none !important;
	}

	.c2 {
		width: 100px !important;
	}

	.c3 {
		width: 100px !important;
	}

	.row {
		cursor: pointer;
	}
</style>