// Components
import App from './App.vue'

import {
	createApp
} from 'vue'

import router from './router';

import {
	createPinia
} from 'pinia';

// Vuetify
import 'vuetify/styles'
import {
	createVuetify
} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader


import VueSplide from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';


import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


const app = createApp(App);
const pinia = createPinia();

const vuetify = createVuetify({
	components,
	directives,
	icons: {
		defaultSet: 'mdi', // This is already the default value - only for display purposes
	}
})

app.use(Antd)
app.use(router);
app.use(pinia);
app.use(vuetify)
app.use(VueSplide)
app.use(ElementPlus)

app.mount('#app')