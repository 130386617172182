<template>
	<div class="header">
		<div class="header-content" :style="{
			width:headerWidth
		}">
			<div class="logo">
				<v-expand-x-transition>
					<span>HASH.FUN</span>
				</v-expand-x-transition>
			</div>
			<div class="router">
				<RouterLink to="/">Home</RouterLink>
				<RouterLink to="/launch">Launch</RouterLink>
				<RouterLink to="/ranking">Ranking</RouterLink>
				<RouterLink to="/docs">Docs</RouterLink>
			</div>
			<div class="lang">
				<v-icon icon="mdi-translate"></v-icon>
			</div>
			<div class="button">
				<v-btn class="btn" rounded="lg">connect</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	let erd = null;
	import elementResizeDetectorMaker from 'element-resize-detector';

	export default {
		name: 'v-header',
		data() {
			return {
				headerWidth: 0,
			}
		},
		mounted() {
			this.controlWidth()
		},
		unmounted() {
			const headerElement = document.querySelector('.header');
			if (headerElement && erd) {
				erd.removeAllListeners(headerElement);
			}
		},
		methods: {
			controlWidth() {
				erd = elementResizeDetectorMaker({
					strategy: "scroll"
				});

				const headerElement = document.querySelector('.header');

				if (headerElement) {
					erd.listenTo(headerElement, (element) => {
						this.headerWidth = element.offsetWidth + 'px';
					});
				}
			},
		}

	}
</script>

<style lang="scss">
	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 68.5px;
		background-color: #101010;

		.header-content {
			position: fixed;
			z-index: 10;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			max-width: 1272px;
			padding: 0 16px;
			
			height: 68.5px;
			background-color: #101010;

			.logo {
				display: none;
				color: #F5F5F5;
				font-weight: bold;
				font-size: 22px;

				@media (max-width:768px) {
					display: block;
					flex: 1;
				}
			}


			.router {
				@media (max-width:768px) {
					display: none;
				}

				flex: 1;
				display: flex;
				align-items: center;

				gap: 80px;

				a {
					font-size: 21px;
					cursor: pointer;
					text-align: center;
					font-weight: bold;
					text-decoration: none;
					color: #F5F5F5;
				}

				a:hover {
					color: #0066ff;
				}
			}


			.lang {
				margin-right: 20px;

				@media (max-width:768px) {
					margin-right: 15px;
				}

				font-size: 18px;
				color: #F5F5F5;
				cursor: pointer;
			}

			.button {

				.btn {
					width: 120px;
					height: 2.625rem;
					font-size: 0.875rem;
					font-weight: bold;
					color: #fff;
					background-color: #0066ff;
				}
			}
		}
	}
</style>