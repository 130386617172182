<template>
	<div class="launch">
		<div class="content">
			<div class="t">
				<div class="from-left">
					<div class="token-meta">
						<div class="basic">
							<div class="logo">
								<div class="title">Image*</div>
								<a-upload v-model:file-list="fileList" name="avatar" list-type="picture-card"
									class="avatar-uploader" :show-upload-list="false"
									action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
									:before-upload="beforeUpload" @change="handleChange">

									<v-img v-if="imageUrl" class="upload" width="360" aspect-ratio="16/9"
										:src="imageUrl" cover></v-img>
									<div class="upload" v-else>
										<v-icon icon="mdi-image-plus" />
										<div>Upload</div>
									</div>

								</a-upload>
							</div>

							<div class="ns">
								<div class="input-item">
									<div class="title">Token Name*</div>
									<div class="input">
										<input type="text" placeholder="Enter the token name" />
									</div>
								</div>
								<div class="input-item">
									<div class="title">Token Symbol*</div>
									<div class="input">
										<input type="text" placeholder="Enter the token symbol" />
									</div>
								</div>
							</div>
						</div>

						<div class="description">
							<div class="input-item">
								<div class="title">Description*</div>
								<textarea class="input" maxlength="256" placeholder="Enter the token description" />
							</div>
						</div>

					</div>
				</div>
				<div class="from-right">
					<div class="token-meta">
						<div class="basic">
							<div class="ns">
								<div class="input-item">
									<div class="title">Website</div>
									<div class="input">
										<input type="text" placeholder="Optional" />
									</div>
								</div>
								<div class="input-item">
									<div class="title">Twitter</div>
									<div class="input">
										<input type="text" placeholder="Optional" />
									</div>
								</div>
								<div class="input-item">
									<div class="title">Telegram</div>
									<div class="input">
										<input type="text" placeholder="Optional" />
									</div>
								</div>
								<div class="button">
									<v-btn class="btn" rounded="lg">deploy token</v-btn>
									<span class="fee">
										<span>Gas: 1 GWei</span>
										Fee：0.1 BNB
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		message
	} from 'ant-design-vue';

	export default {
		name: 'v-launch',
		data() {
			return {
				fileList: [],
				imageUrl: '',
			}
		},
		methods: {
			handleChange(info) {
				if (info.file.status === 'done') {
					message.error('upload done');
				}
				if (info.file.status === 'error') {
					message.error('upload error');
				}

				getBase64(info.file.originFileObj, base64Url => {
					this.imageUrl = base64Url;
				});
			},
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					message.error('You can only upload JPG file!');
				}
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					message.error('Image must smaller than 2MB!');
				}
				return isJpgOrPng && isLt2M;
			}
		}
	}

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}
</script>

<style lang="scss">
	.launch {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.content {
			width: calc(100% - 32px);
			max-width: 1240px;
			will-change: transform;
			margin: 70px 0;

			@media (max-width:768px) {
				margin: 20px 0;
			}

			.t {
				display: flex;
				flex-direction: row;
				justify-content: center;
				flex-wrap: wrap;
				gap: 30px;
				color: #F5F5F5;
				aspect-ratio: 3.659;

				.from-left,
				.from-right {
					flex: 1;
					min-width: 318px;
					max-height: 650px;
					display: flex;
					flex-direction: column;
					align-items: start;
					justify-content: center;

					.token-meta {
						width: 100%;
						max-width: 620px;
						height: 100%;
						display: flex;
						flex-direction: column;

						.basic {
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							flex-wrap: wrap;
							gap: 30px;

							.ns {
								flex: 1;
								display: flex;
								flex-direction: column;
								align-items: start;
								justify-content: space-between;
								min-width: 318px;
								gap: 20px;

								.input-item {
									width: 100%;

									.title {
										margin-bottom: 10px;
										font-weight: bold;
									}

									.input {
										display: flex;
										align-items: center;
										justify-content: center;
										background-color: #202020;
										border-radius: 8px;
										overflow: hidden;
										padding: 0px 15px;
										font-size: 17px;
										height: 55px;

										input {
											color: #F5F5F5;
											width: 100%;
										}
									}

								}
							}

							.logo {
								.title {
									margin-bottom: 10px;
									font-weight: bold;

									@media (max-width:550px) {
										display: none;
									}
								}
							}
						}

						.description {
							width: 100%;
							margin-top: 20px;

							.title {
								margin-bottom: 10px;
								font-weight: bold;
							}

							.input {
								width: 100%;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: #202020;
								border-radius: 8px;
								overflow: hidden;
								padding: 15px;
								font-size: 17px;
								height: 160px;
								max-height: 160px;

								background-color: #202020;
							}
						}

						.button {
							width: 100%;
							height: 85px;
							display: flex;
							flex-direction: row;
							align-items: end;
							justify-content: flex-start;

							@media (max-width:768px) {
								align-items: end;
							}

							.btn {
								width: 180px;
								height: 50px;
								font-size: 0.875rem;
								font-weight: bold;
								color: #FFFFFF;
								background-color: #0066ff;
							}

							.fee {
								width: 100%;
								height: 50px;
								display: flex;
								flex-direction: column;
								align-items: end;
								justify-content: end;
								text-align: right;
								color: #9c9cab;
								gap: 1PX;
								font-size: 14px;
							}
						}

					}
				}

			}
		}
	}


	.upload {
		width: 160px;
		height: 160px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #f5f5f5;
		font-size: 18px;
		background-color: #202020;
	}

	.ant-upload.ant-upload-select {
		width: 160px !important;
		height: 160px !important;
		overflow: hidden !important;
		margin-inline-end: 0px !important;
		margin-bottom: 0px !important;
		border: 0px dashed #F5F5F580 !important;
	}

	.ant-upload.ant-upload-select:hover {
		// border: 1px dashed #0066ff80 !important;
	}

	input:focus,
	textarea:focus {
		caret-color: #F5F5F5;
		outline: none;
	}
</style>